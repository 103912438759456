<template>
  <div>
    <slot name="header" />

    <div class="container mx-auto pb-12 pt-12 max-w-md">
      <div
        v-for="(section, index) in sections"
        class="mb-8"
        :key="index"
      >
        <h2 class="mb-4 font-bold text-xl">
          {{ section.title }}
        </h2>

        <div
          v-for="(slide, i) in section.slides"
          :key="i"
        >
          <div
            v-if="slide.model !== null"
            class="py-3 border-t border-gray-300"
          >
            <h3 class="font-bold text-sm flex items-start">
              <span class="pr-4">
                {{ slide.headline }}
              </span>

              <button
                class="ml-auto text-xs underline mt-1"
                @click.prevent="() => goBackToSlide(slide.key)"
              >
                Endre
              </button>
            </h3>
            <div
              v-if="slide.type === 'bmiSlide' && slide.model && slide.model.height"
              class="mt-3">
              {{ slide.model.height }} cm – {{ slide.model.weight }} kg
            </div>

            <div
              v-else-if="slide.model === true"
              class="mt-3">
              Ja
            </div>

            <div
              v-else-if="slide.model === false"
              class="mt-3">
              Nei
            </div>

            <div v-else-if="Array.isArray(slide.model)">
              <ul class="pl-4 list-disc mt-3">
                <li
                  v-for="(row, j) in slide.model"
                  :key="j"
                >
                  {{ row }}
                </li>
              </ul>
            </div>

            <div v-else-if="slide.type === 'emailSlide'">
              <div class="mt-3">
                {{ formData.email }}
              </div>
            </div>

            <div v-else-if="slide.type === 'newsletterSlide'">
              <div class="mt-3">
                {{ formData.newsletter ? 'Ja takk' : 'Nei takk' }}
              </div>
            </div>

            <div v-else-if="slide.model">
              <div class="mt-3">
                {{ slide.model }}
              </div>
            </div>

            <div v-if="slide.meta && slide.meta.title && slide.meta.model">
              <div class="font-bold text-xs tracking-wide mt-4 mb-2">
                {{ slide.meta.title }}
              </div>

              {{ slide.meta.model }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot :is-valid="true" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    initialFormData: {
      type: Array,
      default () {
        return []
      }
    },

    slides: {
      type: Array,
      default () {
        return []
      }
    },

    goBackToSlide: {
      type: Function,
      default: () => {}
    },
  },

  computed: {
    sections () {
      const sections = []

      this.slides.map((slide, i) => {
        let filterSlides = [
          "bankIdSlide",
          "infoSlide",
          "stopSlide",
          "photoIdSlide",
          "personalNumberSlide",
          "phoneSlide",
          "checkoutSlide",
          "checkoutConfirmationSlide"
        ]

        // Ignore certain slide types
        if (filterSlides.indexOf(slide.type) !== -1) {
          return
        }

        if (i >= this.activeSlideIndex) {
          return
        }

        var existingSection = sections.find(section => section.title === slide.section)

        if (existingSection) {
          existingSection.slides.push(slide)
        } else {
          sections.push({
            title: slide.section,
            slides: [
              slide
            ]
          })
        }
      })

      return sections
    },

    activeSlideIndex () {
      return this.slides.indexOf(this.data)
    }
  }
}
</script>
